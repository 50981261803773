export const LEGACY_URL = `${process.env.REACT_APP_LEGACY_URL}`;
export const BASE_URL = `${process.env.REACT_APP_BASE_URL}:${process.env.REACT_APP_BASE_PORT}`;
export const LAMBDA_URL = `${process.env.REACT_APP_LAMBDA_URL}/hub`;

export const AWS_API_TOKEN = process.env.REACT_APP_AWS_API_TOKEN;

export const USER_IMAGES = `${process.env.REACT_APP_S3_IMAGES}/users`;
export const TEAM_IMAGES = `${process.env.REACT_APP_S3_IMAGES}/teams`;
export const MISC_IMAGES = `${process.env.REACT_APP_S3_IMAGES}/misc`;
export const ARTS_IMAGES = `${process.env.REACT_APP_S3_IMAGES}/arts`;
export const RANKINGS_IMAGES = `${process.env.REACT_APP_S3_IMAGES}/rankings`;
export const BODY_IMAGES = `${process.env.REACT_APP_S3_IMAGES}/body`;
export const CUSTOMER_IMAGES = `${process.env.REACT_APP_S3_IMAGES}/customers`;
export const DEFAULT_IMAGES = `${process.env.REACT_APP_S3_IMAGES}/default`;
export const DOODLE_IMAGES = `${process.env.REACT_APP_S3_IMAGES}/doodles`;
export const LEARN_MORE_IMAGES = `${process.env.REACT_APP_S3_IMAGES}/learn_more`;
export const SCORE_IMAGES = `${process.env.REACT_APP_S3_IMAGES}/score`;

export const TICKET_FILES = process.env.REACT_APP_S3_TICKETS;
export const HUB_FILES = process.env.REACT_APP_S3_FILES;
