import { keys } from 'ramda'
import withStyles from '../../../assets/services/styles'

const base = ``

const sizes = {
    tiny: `heigth: 3rem; width: 3rem;`,
    small: `heigth: 5rem; width: 5rem;`,
    normal: `heigth: 7rem; width: 7rem;`,
    large: `heigth: 9rem; width: 9rem;`,
    huge: `heigth: 11rem; width: 11rem;`,
    huge4xl: `heigth: 19rem; width: 19rem;`
}

const options = {
    size: sizes,
}

export const props = {
    sizes: keys(sizes),
}

export default withStyles(base, options)
