import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Text, Input, Icon } from '../../components';
import Option from './Option';

import styled from 'styled-components';
import { useOutsideClick } from '../../Hooks';

const optionShape = () => {
    return PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string.isRequired,
        format: PropTypes.func,
        // Recursive reference for nested options
        options: PropTypes.arrayOf(optionShape()),
        disabled: PropTypes.bool,
    });
};

const propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    options: PropTypes.arrayOf(
        PropTypes.shape({
            key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            format: PropTypes.func,
            disabled: PropTypes.bool,
        })
    ),

    selectedValue: PropTypes.PropTypes.shape({
        key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string.isRequired,
        format: PropTypes.func,
        disabled: PropTypes.bool,
    }),

    placeholder: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

    label: PropTypes.oneOfType([
        PropTypes.bool,
        PropTypes.shape({
            display: PropTypes.string.isRequired,
            isRequired: PropTypes.bool,
        }),
    ]),

    withSearch: PropTypes.bool,
    onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    icon: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    isRequired: PropTypes.bool,
};

const defaultProps = {
    options: [],

    onChange: false,
    label: false,
    withSearch: false,
    isRequired: false,

    icon: false,
    selectedValue: { key: 'selecione', name: 'Selecione', disabled: true },
};

const Select = ({ children, id, options, onChangeOption, selectedValue, withSearch, label, isRequired, ...props }) => {
    const [isOpenOptions, setIsOpenOptions] = useState(false);
    const [selectedOption, setSelectedOption] = useState(selectedValue);
    const [searchInputValue, setSearchInputValue] = useState('');

    const selectRef = useRef(null);
    useOutsideClick([selectRef], () =>
        setTimeout(() => {
            setIsOpenOptions(false);
        }, 150)
    );

    const openSelect = () => {
        setIsOpenOptions(!isOpenOptions);
    };

    const handleOptionClick = (option) => {
        if (selectedOption?.key !== option.key) {
            setSelectedOption(option);
            onChangeOption && onChangeOption(id, option);
        }

        setTimeout(() => {
            setIsOpenOptions(false);
        }, 150);
    };

    function getSelectWidth() {
        const rect = selectRef.current.getBoundingClientRect();
        return `${rect.width - 8}px`;
    }

    function getOptionsRecursively(options) {
        return options?.flatMap((option) => {
            if (option.options && Array.isArray(option.options) && option.options.length) {
                return getOptionsRecursively(option.options);
            } else {
                return option;
            }
        });
    }

    function getIterationOptions() {
        if (searchInputValue) {
            const allOptions = getOptionsRecursively(options);
            return allOptions.filter((option) => option.name.toUpperCase().includes(searchInputValue.toUpperCase()));
        }

        return options;
    }

    useEffect(() => {
        if (!isOpenOptions) {
            setSearchInputValue('');
        }
    }, [isOpenOptions]);

    useEffect(() => {
        setSelectedOption(selectedValue);
    }, [selectedValue]);

    return (
        <Wrapper ref={selectRef} flexbox column gap='0.5rem' width={props.width}>
            {label && (!label?.position || label?.position === 'outside') && (
                <Wrapper opacity='0.65'>
                    <Text fontSize={label?.fontSize || 'tiny'}>
                        {label.display}
                        {isRequired && '*'}
                    </Text>
                </Wrapper>
            )}

            <Wrapper width={props.width} id={id} flexbox position='relative' borderHover={!isOpenOptions} border={isOpenOptions && '1px solid white'}>
                <Wrapper
                    flexbox
                    bgColor='background.01'
                    padding='5px 10px'
                    justify='space-between'
                    onClick={() => openSelect()}
                    align='center'
                    fontSize='small'
                    width='100%'
                    corner='default'
                    {...props}
                >
                    {label && label?.position === 'inside' ? (
                        <Wrapper flexbox gap opacity='0.8' align='center'>
                            <Text>{label.display}</Text>
                            <Text regular color='white' medium>
                                {selectedOption?.name}
                            </Text>
                        </Wrapper>
                    ) : (
                        <Wrapper opacity='0.8'>
                            <Text regular color='white'>
                                {selectedOption?.name}
                            </Text>
                        </Wrapper>
                    )}

                    <Icon name={isOpenOptions ? 'expand_less' : 'expand_more'} fill='white' />
                </Wrapper>

                {isOpenOptions && (
                    <Wrapper
                        position='absolute'
                        right='0'
                        top='2.5rem'
                        bgColor='background.06'
                        zIndex='99'
                        minWidth='100%'
                        fontSize='small'
                        style={{ boxShadow: '-2px 4px 17px 7px rgba(0, 0, 0, 0.25)' }}
                    >
                        {withSearch && (
                            <Wrapper flexbox padding='6px' bgColor='background.07'>
                                <Input icon='search' value={searchInputValue} onChange={(e) => setSearchInputValue(e.target.value)} />
                            </Wrapper>
                        )}

                        <Wrapper flexbox column maxHeight='15rem' style={{ overflowY: 'scroll' }}>
                            {getIterationOptions().map((option, index) => {
                                return (
                                    <Option
                                        key={index}
                                        width={getSelectWidth()}
                                        option={option}
                                        onClickOption={(option) => handleOptionClick(option)}
                                    />
                                );
                            })}
                        </Wrapper>
                    </Wrapper>
                )}
            </Wrapper>
        </Wrapper>
    );
};

Select.propTypes = propTypes;
Select.defaultProps = defaultProps;

export default Select;
