import React, { useState, useEffect, useRef, useContext } from 'react'
import { FileDisplay, Icon, Modal, Text, Tooltip, Wrapper } from '../../../components'
import { simpleCurrency } from '../../../assets/services/formatters'
import { colors } from '../../../assets/theme'
import { isAdmin, isCoreSector, removeElementsOpacity } from '../../../assets/services/apis'
import Loading from '../Loading'
import moment from 'moment'
import { Form, Wallet, NpsResponses, AllocationHistory, ActivitiesHistory } from './Views'
import PageControl from '../PageControl'
import { BASE_URL, CUSTOMER_IMAGES, DEFAULT_IMAGES } from '../../../Enviroment'
import useImageCheck from '../../../Hooks/useImageCheck'
import { getFallback, postFallback, putFallback } from '../../../assets/services/apis/api'
import { AppContext } from '../../../Context'
import { TableDropdown } from '../../pages/Commercial/Customers/MinhaBase/Components'

const extendedInfosStyle = {
    isSegmented: { color: '#AF60FF', icon: 'sync_alt', tooltip: 'Cliente Segmentado' },
    isTransfer: { color: '#48C8FF', icon: 'sync_problem', tooltip: 'Cliente com Desconto de Transf.' },
    isOpenNps: { color: '#1DC077', icon: 'add_reaction', tooltip: 'NPS em aberto' },
    isWithoutXpCode: { color: '#FF8D07', icon: 'person_alert', tooltip: 'Cliente sem Cód. XP' },
}

const profileInfo = [
    { key: 'validator', name: 'Validator', color: '#87B3F4' },
    { key: 'delegator', name: 'Delegator', color: '#5CF3B0' },
    { key: 'descricionario', name: 'Descricionário', color: '#FFFA80' },
]

const accountTypeInfo = [
    { key: 'main', name: 'Principal', color: '#5CF3B0' },
    { key: 'dependent', name: 'Dependente', color: '#FFFA80' },
]

const CustomerWalletModal = ({ isOpen, closeModal, selectedCustomer }) => {

    const { globalContext } = useContext(AppContext)

    const requestUserInfo = globalContext?.user
    const requestUserSector = requestUserInfo?.sectorId

    const pageControlViews = [
        { key: 'form', display: 'DADOS GERAIS' },
        { key: 'wallet', display: 'CARTEIRA' },
        { key: 'nps', display: 'NPS' },
        { key: 'allocations', display: 'ALOCAÇÕES' },
        { key: 'activities', display: 'ATIVIDADES' },
    ]

    const isAbleToUpdateProfile = isCoreSector(requestUserSector, true, true) || isAdmin(requestUserSector)

    const [selectedView, setSelectedView] = useState('form')
    const [filePreviews, setFilePreviews] = useState(null);

    const [selectedProfile, setSelectedProfile] = useState(null)
    const [selectedAccountType, setSelectedAccountType] = useState(null)

    const [customer, setCustomer] = useState(null)
    const [viewsInfo, setViewsInfo] = useState(null)

    useImageCheck(`${CUSTOMER_IMAGES}/${selectedCustomer}/profile`, () => setFilePreviews(`${DEFAULT_IMAGES}/customer.png`))

    const [isLoadingContent, setIsLoadingContent] = useState({
        info: true, form: true, wallet: true, nps: true, allocations: true, activities: true
    })

    const viewComponents = {
        form: Form,
        wallet: Wallet,
        nps: NpsResponses,
        allocations: AllocationHistory,
        activities: ActivitiesHistory
    }

    async function getCustomerForm(id) {
        setIsLoadingContent(prevState => { return { ...prevState, form: true } })

        const queryParams = { id }

        const url = new URL(`${BASE_URL}/commercial/customers/modal/form`)
        url.search = new URLSearchParams(queryParams)

        const response = await getFallback(url, false, 'only-warning')
        const data = response.data

        setViewsInfo(prevState => {
            return { ...prevState, form: data }
        })

        setIsLoadingContent(prevState => { return { ...prevState, form: false } })
    }

    async function getNpsResponses(id) {
        setIsLoadingContent(prevState => { return { ...prevState, nps: true } })

        const queryParams = { id }

        const url = new URL(`${BASE_URL}/commercial/customers/modal/nps`)
        url.search = new URLSearchParams(queryParams)

        const response = await getFallback(url, false, 'only-warning')
        const data = response.data

        setViewsInfo(prevState => {
            return { ...prevState, nps: data }
        })

        setIsLoadingContent(prevState => { return { ...prevState, nps: false } })
    }

    async function getCustomerWallet(id) {
        setIsLoadingContent(prevState => { return { ...prevState, wallet: true } })

        const queryParams = { id }

        const url = new URL(`${BASE_URL}/commercial/customers/modal/wallet`)
        url.search = new URLSearchParams(queryParams)

        const response = await getFallback(url, false, 'only-warning')
        const data = response.data

        setViewsInfo(prevState => {
            return { ...prevState, wallet: data }
        })

        setIsLoadingContent(prevState => { return { ...prevState, wallet: false } })
    }

    async function getCustomerInfo(id) {
        setIsLoadingContent(prevState => { return { ...prevState, info: true } })

        const queryParams = { id }

        const url = new URL(`${BASE_URL}/commercial/customers/modal/info`)
        url.search = new URLSearchParams(queryParams)

        const response = await getFallback(url, false, 'only-warning')
        const data = response.data

        setCustomer(data)

        setSelectedProfile(data.customerProfile)
        setSelectedAccountType(data.accountType)

        setIsLoadingContent(prevState => { return { ...prevState, info: false } })
    }

    async function getAllocationHistory(id) {
        setIsLoadingContent(prevState => { return { ...prevState, allocations: true } })

        const queryParams = { id }

        const url = new URL(`${BASE_URL}/commercial/customers/modal/allocations`)
        url.search = new URLSearchParams(queryParams)

        const response = await getFallback(url, false, 'only-warning')
        const data = response.data

        setViewsInfo(prevState => {
            return { ...prevState, allocations: data }
        })

        setIsLoadingContent(prevState => { return { ...prevState, allocations: false } })
    }

    async function getActivitiesHistory(id) {
        setIsLoadingContent(prevState => { return { ...prevState, activities: true } })

        const queryParams = { id }

        const url = new URL(`${BASE_URL}/commercial/customers/modal/activities`)
        url.search = new URLSearchParams(queryParams)

        const response = await getFallback(url, false, 'only-warning')
        const data = response.data

        setViewsInfo(prevState => {
            return { ...prevState, activities: data }
        })

        setIsLoadingContent(prevState => { return { ...prevState, activities: false } })
    }

    const handleProfileImageChange = async event => {
        const files = Array.from(event.target.files);

        if (files.length) {
            const file = files[0]

            const reader = new FileReader();

            reader.onloadend = () => {
                setFilePreviews(reader.result)
            };

            reader.readAsDataURL(file);

            const formData = new FormData();

            formData.append('file', file);

            const requestInfo = {
                method: 'PUT',
                body: formData,
            };

            const queryParams = { id: customer?.customerCode }

            const url = new URL(`${BASE_URL}/commercial/customers/modal/update/customer/image`);
            url.search = new URLSearchParams(queryParams).toString()

            const response = await putFallback(url, formData, false, false, {});
        }

    };

    const handleFileChange = async files => {
        const formData = new FormData();

        files.forEach((file, index) => {
            formData.append(`files[${index}]`, file);
        });

        const queryParams = { id: customer?.customerCode }

        const url = new URL(`${BASE_URL}/commercial/customers/modal/update/customer/files`);
        url.search = new URLSearchParams(queryParams).toString();

        const response = await postFallback(url, formData, true, false, { 'cache-control': 'no-cache' })
        const data = await response.json()

        setCustomer(prevState => { return { ...prevState, files: [...prevState.files, ...data] } })

        return data
    }

    async function onEditFormField(key, value) {
        const previousValue = customer[key]
        const url = new URL(`${BASE_URL}/commercial/customers/modal/form/update`)

        const queryParams = { id: selectedCustomer }

        const body = { key, value }

        url.search = new URLSearchParams(queryParams)

        const response = putFallback(url, JSON.stringify(body), false, true).then(res => {
            if (!res.ok) { trigAsynchronousFunctions(selectedCustomer) }
        })
    }

    function getFormattedCustomerFiles() {
        const formattedFiles = customer?.files?.map(file => {
            return {
                name: file.fileName,
                src: `${CUSTOMER_IMAGES}/${selectedCustomer}/${file.s3Id}${file.extension}`,
                extension: file.extension
            }
        })

        return formattedFiles
    }

    const checkXpCode = () => {
        return customer?.extendedInfosArray?.some(info => info.key === 'isWithoutXpCode' && info.value)
    }

    const getProfileInfo = (key) => {
        return profileInfo.find(profile => profile.key === key)
    }

    const getAccountTypeInfo = (key) => {
        return accountTypeInfo.find(accountType => accountType.key === key)
    }

    const handleIconClick = (event, link) => {
        event.stopPropagation()
        window.open(link).focus()
    }

    async function trigAsynchronousFunctions(id) {
        getCustomerInfo(id)
        getCustomerForm(id)
        getCustomerWallet(id)
        getNpsResponses(id)
        getAllocationHistory(id)
        getActivitiesHistory(id)
    }

    useEffect(() => {
        if (customer) removeElementsOpacity(100, 'opacity_card_modal')
    }, [customer])

    useEffect(() => {
        if (selectedCustomer) {
            trigAsynchronousFunctions(selectedCustomer)
            setFilePreviews(`${CUSTOMER_IMAGES}/${selectedCustomer}/profile`)
        } else {
            setIsLoadingContent({ info: true, form: true, wallet: true, nps: true })
            setViewsInfo(null)
            setCustomer(null)
            setFilePreviews(null)
            setSelectedView('form')
        }
    }, [selectedCustomer])

    return (
        <Modal open={isOpen} close={closeModal} title={`Cliente ${selectedCustomer}`} width='100vw'>

            <Wrapper flexbox bgColor='background.02' height='80vh' padding='1.5rem 2rem' gap='huge'>

                {
                    !isLoadingContent.info ? (
                        <Wrapper className='opacity_card_modal' flexbox column bgColor='background.03' padding width='20%' corner='bowed' style={{ overflowY: 'scroll' }}>

                            <Wrapper className='opacity_card_modal' flexbox column gap='large' padding='bottom' border='bottom'>

                                <Wrapper flexbox gap='big' align='center'>

                                    <Wrapper flexbox>
                                        <input type="file" id="fileInput" multiple style={{ display: 'none' }} onChange={handleProfileImageChange} />
                                        <label htmlFor="fileInput" id="fileLabel" style={{ cursor: 'pointer' }}>
                                            <Wrapper flexbox width='4rem' height='4rem' corner='circle' bgColor='background.06'
                                                bgImage={`url(${filePreviews})`} style={{ backgroundSize: 'cover' }} />
                                        </label>
                                    </Wrapper>

                                    <Wrapper flexbox column maxWidth='calc(100% - 7rem)'>
                                        <Text fontSize='small'>{customer?.customerName || '-'}</Text>
                                        <Text fontSize='tiny' opacity='0.8'>{customer?.customerCode || 0}</Text>
                                    </Wrapper>

                                </Wrapper>

                                <Wrapper flexbox gap='large'>

                                    <TableDropdown
                                        bgColor={selectedProfile ? `${getProfileInfo(selectedProfile)?.color}33` : 'background.07'}
                                        textColor={getProfileInfo(selectedProfile)?.color || 'white'}
                                        corner='default'
                                        padding='5px 10px'
                                        isAbleToUpdate={isAbleToUpdateProfile}
                                        tagComponent={<Text fontSize='tiny' color={getProfileInfo(selectedProfile)?.color}>{getProfileInfo(selectedProfile)?.name || 'Sem perfil'}</Text>}
                                        height='25px'
                                    >
                                        {
                                            profileInfo.map((profile, index) => {
                                                return (
                                                    <Wrapper key={index} flexbox align='center' bgHover='background.09' padding='0.5rem'
                                                        onClick={() => { onEditFormField('customer_profile', profile.key); setSelectedProfile(profile.key) }}>
                                                        <Text fontSize='tiny' color={profile.color}>{profile.name}</Text>
                                                    </Wrapper>
                                                )
                                            })
                                        }
                                    </TableDropdown>

                                    <TableDropdown
                                        bgColor={selectedAccountType ? `${getAccountTypeInfo(selectedAccountType)?.color}33` : 'background.07'}
                                        textColor={getAccountTypeInfo(selectedAccountType)?.color || 'white'}
                                        corner='default'
                                        padding='5px 10px'
                                        isAbleToUpdate={isAbleToUpdateProfile}
                                        tagComponent={<Text fontSize='tiny' color={getAccountTypeInfo(selectedAccountType)?.color}>{getAccountTypeInfo(selectedAccountType)?.name || 'Sem perfil'}</Text>}
                                        height='25px'
                                    >
                                        {
                                            accountTypeInfo.map((accountType, index) => {
                                                return (
                                                    <Wrapper key={index} flexbox align='center' bgHover='background.09' padding='0.5rem'
                                                        onClick={() => { onEditFormField('account_type', accountType.key); setSelectedAccountType(accountType.key) }}>
                                                        <Text fontSize='tiny' color={accountType.color}>{accountType.name}</Text>
                                                    </Wrapper>
                                                )
                                            })
                                        }
                                    </TableDropdown>

                                </Wrapper>

                            </Wrapper>

                            <Wrapper className='opacity_card_modal' flexbox justify='space-between' padding='0.5rem 0' border='bottom' align='center'>

                                <Wrapper flexbox gap align='center'>
                                    {
                                        customer?.extendedInfosArray?.filter(info => info.value).map((info, idx) => {
                                            const infoStyle = extendedInfosStyle[info.key]
                                            return (
                                                <Tooltip key={idx} description={infoStyle.tooltip} zIndex={999999}>
                                                    <Wrapper flexbox center padding='4px' bgColor={`${infoStyle.color}1F`} corner='default'>
                                                        <Icon name={infoStyle.icon} fill={infoStyle.color} size={18} />
                                                    </Wrapper>
                                                </Tooltip>
                                            )
                                        })
                                    }
                                </Wrapper>

                                <Wrapper flexbox gap>

                                    <Tooltip type="simple" description='Pipe drive'>    
                                        <Wrapper flexbox center padding='4px' bgColor={customer?.dealId ? 'background.06' : `${colors.getColor('danger')}33`}
                                            bgHover={customer?.dealId ? 'background.08' : `${colors.getColor('danger')}4D`} transition='background-color 0.2s ease-in-out' corner='default'
                                            onClick={(event) => customer?.dealId && handleIconClick(event, `https://guiainvest-270444.pipedrive.com/deal/${customer.dealId}/`)}>
                                            <Icon name='pipedrive' fill='white' size={16} />
                                        </Wrapper>
                                    </Tooltip>

                                    <Tooltip type="simple" description='Hub XP'>
                                        <Wrapper flexbox center padding='4px' bgColor='background.06' bgHover='background.08' transition='background-color 0.2s ease-in-out' corner='default'
                                            onClick={(event) => handleIconClick(event, `https://hub.xpi.com.br/rede/#/customers/${window.btoa(customer.customerCode)}/consolidated-position`)}>
                                            <Icon name='xp' fill='white' size={20} />
                                        </Wrapper>
                                    </Tooltip>

                                </Wrapper>

                            </Wrapper>

                            <Wrapper className='opacity_card_modal' flexbox column gap='2rem' border='bottom' padding='vertical'>

                                <Wrapper flexbox>

                                    <Wrapper flexbox column width='50%'>
                                        <Text fontSize='nano' opacity='0.7'>Último Contato</Text>
                                        <Text fontSize='small'>{checkXpCode() ? '-' : moment().subtract(customer?.daysSinceLastContact, 'days').format('DD/MM/YYYY')}</Text>
                                    </Wrapper>

                                    <Wrapper flexbox column width='50%'>
                                        <Text fontSize='nano' opacity='0.7'>Próximo Contato</Text>
                                        <Text fontSize='small'>{checkXpCode() ? '-' : moment().add(customer?.daysToNextContact, 'days').format('DD/MM/YYYY')}</Text>
                                    </Wrapper>

                                </Wrapper>

                                <Wrapper flexbox>

                                    <Wrapper flexbox column width='50%'>
                                        <Text fontSize='nano' opacity='0.7'>Última Reunião</Text>
                                        <Text fontSize='small'>{checkXpCode() ? '-' : moment().subtract(customer?.daysSinceLastMeeting, 'days').format('DD/MM/YYYY')}</Text>
                                    </Wrapper>

                                    <Wrapper flexbox column width='50%'>
                                        <Text fontSize='nano' opacity='0.7'>Próxima Reunião</Text>
                                        <Text fontSize='small'>{checkXpCode() ? '-' : moment().add(customer?.daysToNextMeeting, 'days').format('DD/MM/YYYY')}</Text>
                                    </Wrapper>

                                </Wrapper>

                                <Wrapper flexbox column>
                                    <Text fontSize='nano' opacity='0.7'>Custódia</Text>
                                    <Text fontSize='small'>{simpleCurrency(customer?.net)}</Text>
                                </Wrapper>

                                <Wrapper flexbox column>
                                    <Text fontSize='nano' opacity='0.7'>Telefone</Text>
                                    <Text fontSize='small'>{customer?.phone}</Text>
                                </Wrapper>

                                <Wrapper flexbox column>
                                    <Text fontSize='nano' opacity='0.7'>E-mail</Text>
                                    <Text fontSize='small'>{customer?.email}</Text>
                                </Wrapper>

                            </Wrapper>

                            <Wrapper margin='top'>
                                <FileDisplay
                                    files={getFormattedCustomerFiles() || []}
                                    withLoader
                                    onAddFile={handleFileChange}
                                />
                            </Wrapper>

                        </Wrapper>
                    ) : (
                        <Wrapper flexbox width='20%' gap='3rem' bgColor='background.03' padding corner='bowed'>
                            <Loading type='pie' height='5rem' width='5rem' />
                            <Wrapper flexbox column gap width='60%'>
                                <Loading type='box' height='1.5rem' />
                                <Loading type='box' height='1.5rem' />
                            </Wrapper>
                        </Wrapper>
                    )
                }

                <Wrapper flexbox column bgColor='background.03' width='80%' corner='bowed' maxHeight='100%' height='fit-content'>

                    <Wrapper border='bottom'>
                        <PageControl
                            leftControl={{
                                views: pageControlViews,
                                selectedValue: selectedView
                            }}
                            handleChangeLeftControl={(view) => setSelectedView(view.key)}
                        />
                    </Wrapper>

                    <Wrapper flex style={{ overflowY: 'scroll' }} height='fit-content'>
                        {
                            React.createElement(viewComponents[selectedView],
                                { requestUserInfo, customer, viewInfo: viewsInfo?.[selectedView], isLoading: isLoadingContent?.[selectedView], onEditFormField: onEditFormField })
                        }
                    </Wrapper>

                </Wrapper>

            </Wrapper>

        </Modal>
    )
}

export default CustomerWalletModal