import moment from 'moment';
import momentTimezone from 'moment-timezone';

import timezones from './timezone';

const humanizeDuration = (duration) => {
    if (duration.days()) {
        return `${duration.days()}d ${duration.hours()}h ${duration.minutes()}m`;
    }

    if (duration.hours()) {
        return `${duration.hours()}h ${duration.minutes()}m`;
    }

    if (duration.minutes()) {
        return `${duration.minutes()}m ${duration.seconds()}s`;
    }

    return `${duration.seconds()}s`;
};

const getDuration = (begin, end) => {
    const duration = moment.duration(moment(end).diff(moment(begin)));

    if (duration.days()) {
        return `${duration.days()}d ${duration.hours()}h ${duration.minutes()}m`;
    }

    if (duration.hours()) {
        return `${duration.hours()}h ${duration.minutes()}m`;
    }

    if (duration.minutes()) {
        return `${duration.minutes()}m ${duration.seconds()}s`;
    }

    return `${duration.seconds()}s`;
};

const momentOrDefault = (date, format = '', defaultValue) => {
    return date ? moment.utc(date).format(format) : defaultValue;
};

const getCurrentMonth = () => {
    const startOfMonth = moment().startOf('month');
    const endOfMonth = moment().endOf('month');

    return [startOfMonth, endOfMonth];
};

function getCurrentWeek() {
    const startOfWeek = moment().startOf('week');
    const endOfWeek = moment().endOf('week');

    return [startOfWeek, endOfWeek];
}

const getLastWeek = () => {
    const startOfLastWeek = moment().subtract(1, 'weeks').startOf('week');
    const endOfLastWeek = moment().subtract(1, 'weeks').endOf('week');

    return [startOfLastWeek, endOfLastWeek];
};

const getLast30Days = () => {
    const start = moment().subtract(29, 'days');
    const end = moment();

    return [start, end];
};

const getLastMonth = () => {
    const startOfLastMonth = moment().subtract(1, 'month').startOf('month');
    const endOfLastMonth = moment().subtract(1, 'month').endOf('month');

    return [startOfLastMonth, endOfLastMonth];
};

const getCurrentQuarter = () => {
    const currentQuarter = moment()?.quarter();
    const firstDayOfQuarter = moment()?.quarter(currentQuarter).startOf('quarter');
    const lastDayOfQuarter = moment()?.quarter(currentQuarter).endOf('quarter');

    return [firstDayOfQuarter, lastDayOfQuarter];
};

const getYearToDay = () => {
    const firstDayOfYear = moment()?.startOf('year');
    const lastDayOfYear = moment()?.endOf('year');

    return [firstDayOfYear, lastDayOfYear];
};

const getCurrentSemester = () => {
    const currentSemester = getCurrentMonth() <= 6 ? 1 : 2;
    const firstDaySemester = currentSemester === 1 ? moment()?.startOf('year').format('YYYY-MM-DD') : moment()?.month(6).startOf('month');

    const lastDaySemester = currentSemester === 1 ? moment()?.month(5).endOf('month').format('YYYY-MM-DD') : moment()?.endOf('year');

    return [firstDaySemester, lastDaySemester];
};

const getLast18Months = () => {
    const currentDay = moment();
    const dayAYearAgo = moment()?.subtract(18, 'months');

    return [dayAYearAgo, currentDay];
};

const getLast12Months = () => {
    const currentDay = moment();
    const dayAYearAgo = moment()?.subtract(12, 'months');

    return [dayAYearAgo, currentDay];
};

const getFirstAndLastDay = (year, month) => {
    var firstDay = new Date(year, month - 1, 1);
    var lastDay = new Date(year, month, 0);
    return { firstDay, lastDay };
};

const getAvelLifeTime = () => {
    const firstDay = moment('03/01/2019');
    const currentDay = moment();

    return [firstDay, currentDay];
};

export {
    moment,
    momentTimezone,
    timezones,
    humanizeDuration,
    getDuration,
    momentOrDefault,
    getFirstAndLastDay,
    getCurrentMonth,
    getCurrentWeek,
    getLastWeek,
    getLast30Days,
    getLastMonth,
    getCurrentQuarter,
    getYearToDay,
    getCurrentSemester,
    getLast12Months,
    getLast18Months,
    getAvelLifeTime,
};

export default momentTimezone;
