import React, { useCallback, useState, useEffect, useMemo } from 'react';
import Wrapper from '../../../Wrapper';
import Text from '../../../Text';
import Icon from '../../../Icon';
import { percentageWithoutDecimal } from '../../../../assets/services/formatters';
import styled from 'styled-components';
import { MISC_IMAGES } from '../../../../Enviroment';

const TableRow = styled.tr.attrs(props => ({
    style: {
        backgroundImage: props.rankingRows && `linear-gradient(to right, 
            ${getPositionColor(props.rowIndex, props.rankingColor)}, transparent ${getGradientSize(props.rowIndex)})`,
        height: props?.lineHeight || 'fit-content',
    }
}))``

function getGradientSize(index) {
    return index <= 4 ? percentageWithoutDecimal(0.50 - index * 0.05) : 0;
}

function getPositionColor(index, rankingColor) {
    return index === 0 ? (rankingColor ? `${rankingColor}1F` : '#916e16') : index === 1 ? (rankingColor ? `${rankingColor}1A` : '#757474') : index === 2 ? (rankingColor ? `${rankingColor}0F` : '#995f26') : (rankingColor ? '' : '#3b393d');
}

const Row = ({ item, columns, onUpdateValue, showColumns, dropdownColumn, numberOfColumns, hasDelete, formatController, isSelectable, rowProps,
    rowIndex, fullData, dispatch, handleSelectedRows, handleDeletedRows }) => {

    const badges = [ 'first_badge.png', 'second_badge.png', 'third_badge.png'];

    const [isOpenRow, setIsOpenRow] = useState(false)

    const handleDataFormat = useCallback((item, column) => {
        if (column?.format) {
            const isKnownFormat = typeof column.format === 'string'

            return isKnownFormat ? (formatController?.[column.format](item, column.key) || column.format(item, column.key, rowIndex, onUpdateValue, fullData, dispatch))
                : column.format(item, column.key, rowIndex, onUpdateValue, fullData, dispatch)
        }

        return item[column.key]
    }, [item, fullData])

    return (
        <React.Fragment>

            <TableRow rowIndex={rowIndex} rankingRows={rowProps?.rankingRows} rankingColor={rowProps?.rankingColor} lineHeight={rowProps?.lineHeight}
                onClick={() => dropdownColumn && setIsOpenRow(prevState => !prevState)} className={rowProps?.className}>
                {
                    columns.map((column, index) => {

                        return (
                            (column?.show ?? true) && (
                                <td key={`${item.uuid}-${column.key}-${index}`} colSpan={column?.colSpan || 1}
                                    style={{ padding: rowProps?.padding || '0.75rem', cursor: dropdownColumn ? 'pointer' : 'default' }}>
                                    <Wrapper position='relative' flexbox align='center' gap='big'>

                                        {
                                            isSelectable && index === 0 && (
                                                (item.hasOwnProperty('__isSelectableRow$') && !item?.__isSelectableRow$) ? (
                                                    <Wrapper
                                                        flexbox
                                                        cursor='not-allowed'
                                                        opacity='0.3'
                                                        margin='0 2rem 0 0'
                                                        center height='1.2rem'
                                                        width='1.2rem'
                                                        minWidth='1.2rem'
                                                        corner='default'
                                                        border
                                                        bgColor='background.01'
                                                    />
                                                ) : (
                                                    <Wrapper margin='0 2rem 0 0' flexbox center height='1.2rem' width='1.2rem' minWidth='1.2rem'
                                                        corner='default' border bgColor='background.01'
                                                        onClick={(event) => { event.stopPropagation(); handleSelectedRows(item) }}>
                                                        {item?.__isRowSelected$ && <Icon name='done' fill='success' size={18} />}
                                                    </Wrapper>
                                                ))
                                        }

                                        {
                                            rowProps?.rankingRows && index === 0 && (
                                                rowIndex >= 0 && rowIndex <= 2 ? (
                                                    <img src={`${MISC_IMAGES}/${badges[rowIndex]}`} style={{ width: '25px', height: '25px'}}/>
                                                ) : (
                                                    <Text width="25px" fontSize={rowProps?.fontSize || false}>{rowIndex + 1}.</Text>
                                                )
                                            )
                                        }

                                        <Wrapper width='100%' fontSize={rowProps?.fontSize || false}>
                                            {handleDataFormat(item, column)}
                                        </Wrapper>

                                        {
                                            (hasDelete && index === numberOfColumns) && (
                                                <Icon
                                                    name='close'
                                                    fill='danger'
                                                    size={20}
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={(event) => { event.stopPropagation(); handleDeletedRows(item) }}
                                                />
                                            )
                                        }

                                        {
                                            (dropdownColumn && index === numberOfColumns) && (
                                                <Icon name={isOpenRow ? 'expand_less' : 'expand_more'} fill='white' size={30}
                                                    style={{ paddingRight: '0.75rem' }} />
                                            )
                                        }

                                    </Wrapper>
                                </td>
                            )
                        )
                    })
                }
            </TableRow>

            {
                (isOpenRow && dropdownColumn) && (
                    <td colSpan={showColumns.some(column => column?.colSpan) ? showColumns.reduce((prev, curr) => prev + (curr?.colSpan || 1), 0)
                        : showColumns.length}>
                        {
                            dropdownColumn.format
                                ? dropdownColumn.format(item, dropdownColumn.key)
                                : item[dropdownColumn.key]
                        }
                    </td>
                )
            }

        </React.Fragment>
    )
}

const areEqual = (prevProps, nextProps) => {
    return (
        prevProps.item === nextProps.item &&
        prevProps.columns === nextProps.columns
    );
};

export default React.memo(Row, areEqual);
