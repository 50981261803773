import EventBus from "../../../../Events/EventBus";
import { v4 as uuidv4 } from 'uuid';

function updateProgress(progress, isApiOk) {
    EventBus.emit('update-fetch-progress', { detail: progress, isApiOk });
}

async function fetchFallback(resource, loadingAnimation, httpStatusDisplay, init) {
    if (loadingAnimation) {
        EventBus.emit('show-fetch-modal');
    }

    try {
        const response = await fetch(resource, init);

        if (loadingAnimation) {
            updateProgress(100, response?.ok);

            setTimeout(() => {
                EventBus.emit('hide-fetch-modal');
            }, 1000);
        }

        if (httpStatusDisplay) {
            const responseId = uuidv4();
            const json = await response.json()

            const completedJson = { ...json, status: response.status, uuid: responseId, ok: response.status === 200, warning: response.status > 200 && response.status < 300 }
            const timeoutTime = completedJson.status === 200 ? 3000 : 8000

            if (httpStatusDisplay === 'only-warning' && response.status !== 200) {
                EventBus.emit('send-http-response', completedJson);

                setTimeout(() => {
                    EventBus.emit('dismiss-http-response', responseId);
                }, timeoutTime)

            } else if (httpStatusDisplay === true) {

                EventBus.emit('send-http-response', completedJson);

                setTimeout(() => {
                    EventBus.emit('dismiss-http-response', responseId);
                }, timeoutTime)
            }

            return completedJson
        } else {

            if (!response.ok) {
                return console.log('Error')
            }

            return response
        }

    } catch (error) {
        console.log(error)
    }
}

export async function getFallback(resource, loadingAnimation = false, httpStatusDisplay = false, abortController = new AbortController(), headers = {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
}) {
    const response = await fetchFallback(resource, loadingAnimation, httpStatusDisplay, {
        method: 'GET',
        headers: headers,
        signal: abortController.signal
    });

    return response
}

export async function postFallback(resource, body, loadingAnimation = false, httpStatusDisplay = false, headers = {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
}) {
    const response = await fetchFallback(resource, loadingAnimation, httpStatusDisplay, {
        method: 'POST',
        body: body,
        headers: headers,
    });

    return response;
}

export async function putFallback(resource, body, loadingAnimation = false, httpStatusDisplay = false, headers = {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
}) {
    const response = await fetchFallback(resource, loadingAnimation, httpStatusDisplay, {
        method: 'PUT',
        body: body,
        headers: headers,
    });

    return response;
}

export async function deleteFallback(resource, body, loadingAnimation = false, httpStatusDisplay = false, headers = {
    'Content-Type': 'application/json',
    'cache-control': 'no-cache',
}) {
    const response = await fetchFallback(resource, loadingAnimation, httpStatusDisplay, {
        method: 'DELETE',
        body: body,
        headers: headers,
    });
    if (!response.ok) {
        // if (response.status === 477) return await this.showModalWarning(response);
        // return await this.showModalError(response);
        return console.log('errado')
    }
    return response;
}