import React, { useContext, lazy } from 'react';
import { AppContext } from './Context';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import BaseTemplate from './Template';
import PrivateRoute from './Auth';

const HomePage = lazy(() =>
    import(
        /* webpackChunkName: 'HomePage'*/
        /* webpackPrefetch: true */
        './App/pages/Home'
    )
);
// --------------------------------------------
const MinhaBase = lazy(() =>
    import(
        /* webpackChunkName: 'MinhaBase'*/
        /* webpackPrefetch: true */
        './App/pages/Commercial/Customers/MinhaBase'
    )
);

const FunilProspeccao = lazy(() =>
    import(
        /* webpackChunkName: 'FunilProspeccao'*/
        /* webpackPrefetch: true */
        './App/pages/Commercial/FunilProspeccao'
    )
);

// --------------------------------------------
const IndividualIs = lazy(() =>
    import(
        /* webpackChunkName: 'IndividualIs'*/
        /* webpackPrefetch: true */
        './App/pages/Performance/Individual/IS'
    )
);

const MinhasMetas = lazy(() =>
    import(
        /* webpackChunkName: 'MinhasMetas'*/
        /* webpackPrefetch: true */
        './App/pages/Performance/MinhasMetas'
    )
);

// --------------------------------------------
const RankingIs = lazy(() =>
    import(
        /* webpackChunkName: 'RankingIs'*/
        /* webpackPrefetch: true */
        './App/pages/Hub/Rankings/IS'
    )
);

const RankingConsultoria = lazy(() =>
    import(
        /* webpackChunkName: 'RankingIs'*/
        /* webpackPrefetch: true */
        './App/pages/Hub/Rankings/Consultoria'
    )
);

const PowerBI = lazy(() =>
    import(
        /* webpackChunkName: 'PowerBi'*/
        /* webpackPrefetch: true */
        './App/pages/Hub/PowerBI'
    )
);

const Tickets = lazy(() =>
    import(
        /* webpackChunkName: 'Tickets'*/
        /* webpackPrefetch: true */
        './App/pages/Hub/Tickets'
    )
);

const Leaderboards = lazy(() =>
    import(
        /* webpackChunkName: 'Leaderboards'*/
        /* webpackPrefetch: true */
        './App/pages/Hub/Leaderboards'
    )
);

const PJInfo = lazy(() =>
    import(
        /* webpackChunkName: 'PjInfo'*/
        /* webpackPrefetch: true */
        './App/pages/Hub/PJInfo'
    )
);

const Score = lazy(() =>
    import(
        /* webpackChunkName: 'Score'*/
        /* webpackPrefetch: true */
        './App/pages/Hub/Score'
    )
);

const Profile = lazy(() => import('./App/pages/Hub/Profile'));

// --------------------------------------------

const CommissionsReport = lazy(() =>
    import(
        /* webpackChunkName: 'RankingIs'*/
        /* webpackPrefetch: true */
        './App/pages/Reports/CommissionsReport'
    )
);

// --------------------------------------------

const SdrForm = lazy(() =>
    import(
        /* webpackChunkName: 'RankingIs'*/
        /* webpackPrefetch: true */
        './App/pages/Commercial/SdrForm'
    )
);

const RoutesController = () => {
    const { globalContext, setGlobalContext } = useContext(AppContext);

    return (
        <Router>
            <Routes>
                <Route path='/' element={<Navigate to='/home' />} />

                <Route path='/' element={<BaseTemplate />}>
                    <Route path='settings' element={<PrivateRoute pageId={69} key='settings' />}>
                        <Route path='' element={<Profile globalContext={globalContext} />} />
                    </Route>

                    <Route path='home' element={<PrivateRoute pageId={0} key='home' />}>
                        <Route path='' element={<HomePage globalContext={globalContext} />} />
                    </Route>

                    <Route path='performance'>
                        <Route path='minhas-metas' element={<PrivateRoute pageId={1} key='minhas-metas' />}>
                            <Route path='' element={<MinhasMetas globalContext={globalContext} />} />
                        </Route>

                        <Route path='individual/is' element={<PrivateRoute pageId={2} key='individual-is' />}>
                            <Route path='' element={<IndividualIs globalContext={globalContext} />} />
                        </Route>
                    </Route>

                    <Route path='commercial'>
                        <Route path='customers/portfolio' element={<PrivateRoute pageId={3} key='customers-portfolio' />}>
                            <Route path='' element={<MinhaBase globalContext={globalContext} />} />
                        </Route>

                        <Route path='funnel' element={<PrivateRoute pageId={63} key='funnel' />}>
                            <Route path='' element={<FunilProspeccao globalContext={globalContext} />} />
                        </Route>

                        <Route path='sdr-form' element={<PrivateRoute pageId={70} key='sdr-form' />}>
                            <Route path='' element={<SdrForm globalContext={globalContext} />} />
                        </Route>
                    </Route>

                    <Route path='reports'>
                        <Route path='commissions' element={<PrivateRoute pageId={68} key='commissions-report' />}>
                            <Route path='' element={<CommissionsReport globalContext={globalContext} />} />
                        </Route>
                    </Route>

                    <Route path='hub'>
                        <Route path='score' element={<PrivateRoute pageId={26} key='score' />}>
                            <Route path='' element={<Score globalContext={globalContext} />} />
                        </Route>

                        <Route path='ranking/is' element={<PrivateRoute pageId={29} key='ranking-is' />}>
                            <Route path='' element={<RankingIs />} />
                        </Route>

                        <Route path='ranking/consultoria' element={<PrivateRoute pageId={28} key='ranking-consultoria' />}>
                            <Route path='' element={<RankingConsultoria globalContext={globalContext} setGlobalContext={setGlobalContext} />} />
                        </Route>

                        <Route path='power-bi' element={<PrivateRoute pageId={31} key='power-bi' />}>
                            <Route path='' element={<PowerBI />} />
                        </Route>

                        <Route path='tickets' element={<PrivateRoute pageId={64} key='tickets' />}>
                            <Route path='' element={<Tickets />} />
                        </Route>

                        <Route path='pj-info' element={<PrivateRoute pageId={32} key='pj-info' />}>
                            <Route path='' element={<PJInfo />} />
                        </Route>

                        <Route path='leaderboards' element={<PrivateRoute pageId={30} key='leaderboards' />}>
                            <Route path='' element={<Leaderboards />} />
                        </Route>
                    </Route>

                    <Route path='*' element={<h1>NOT FOUND</h1>} />
                </Route>
            </Routes>
        </Router>
    );
};

export default RoutesController;
