import React, { useState, useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { useOutsideClick } from '../../Hooks';

const Portal = ({ triggerRef, isOpen, onClose, children, portalStyle = {}, containerPadding = 10, customTop = 0, defaultWidth }) => {
    const portalRef = useRef(null);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const [isPositionReady, setIsPositionReady] = useState(false);

    useOutsideClick([portalRef], onClose || (() => {}));

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = '';
        }
        return () => {
            document.body.style.overflow = '';
        };
    }, [isOpen]);

    useEffect(() => {
        if (isOpen) {
            const scrollContainer = document.querySelector('#template-container');

            const handleScroll = () => {
                if (onClose) {
                    onClose();
                }
            };

            if (scrollContainer) {
                scrollContainer.addEventListener('scroll', handleScroll);
            }

            return () => {
                if (scrollContainer) {
                    scrollContainer.removeEventListener('scroll', handleScroll);
                }
            };
        }
    }, [isOpen, onClose]);

    useEffect(() => {
        if (isOpen && triggerRef.current) {
            const triggerRect = triggerRef.current.getBoundingClientRect();
            const portalWidth = portalRef.current?.offsetWidth || defaultWidth;

            let leftPosition = triggerRect.left + window.scrollX;
            let topPosition = triggerRect.bottom + window.scrollY;

            if (triggerRect.left + portalWidth > window.innerWidth) {
                leftPosition = triggerRect.right - portalWidth;
            }

            if (leftPosition < containerPadding) {
                leftPosition = containerPadding;
            }

            setPosition({
                top: topPosition,
                left: leftPosition,
            });

            setIsPositionReady(true);
        } else {
            setIsPositionReady(false);
        }
    }, [isOpen, triggerRef, containerPadding]);

    if (!isOpen || !isPositionReady) return null;

    return ReactDOM.createPortal(
        <div
            ref={portalRef}
            style={{
                position: 'absolute',
                top: `${position.top + customTop}px`,
                left: `${position.left}px`,
                zIndex: 99999999,
                ...portalStyle,
            }}
        >
            {children}
        </div>,
        document.body
    );
};

export default Portal;
