import React, { useEffect, useState } from 'react';
import { Wrapper, Text } from '../../../components';
import styled from 'styled-components';
import withStyles, { props } from './styles'
import PropTypes from 'prop-types'
import { colors } from '../../../assets/theme';
import { shortCurrency } from '../../../assets/services/formatters/currency';

const CircularProgress = styled(Wrapper)`
    position: relative;
    border-radius: 50%;
    display: grid;
    place-items: center;

    &:before {
       position: absolute;
       background-color: white;
       height: 50px;
       width: 50px;
       border-radius: 9999px;
    }
`;

const propTypes = {
    size: PropTypes.oneOf(props.sizes),

    currentValue: PropTypes.number,

    totalValue: PropTypes.number,

    color: PropTypes.string,

    displayData: PropTypes.oneOfType([
        PropTypes.oneOf(['value', 'percentage', 'currency']),
        PropTypes.object
    ])

}

const defaultProps = {
    size: 'normal',
    currentValue: 0,
    totalValue: 100,
    color: 'primary',
    displayData: 'percentage'
}

const ProgressGauge = ({ currentValue, totalValue, color, size, fontSize, displayData, ...props }) => {
    const value = (currentValue / totalValue) < 1 ? (currentValue / totalValue) * 100 : 100;
    const duration = 2000;

    const radius = 50;
    const totalCircumference = 2 * Math.PI * radius;
    const visibleCircumference = (270 / 360) * totalCircumference;
    const targetOffset = visibleCircumference - (value / 100) * visibleCircumference;

    const [offset, setOffset] = useState(visibleCircumference);
    const [counter, setCounter] = useState(0);

    useEffect(() => {
        const updateFrequency = 16;
        const numberOfUpdates = duration / updateFrequency;
        const counterStep = value / numberOfUpdates;

        const intervalId = setInterval(() => {
            if (counter < value) {
                setCounter(prevCounter => {
                    const newCounter = prevCounter + counterStep;
                    return newCounter >= value ? value : newCounter;
                });
            } else {
                clearInterval(intervalId);
            }
        }, updateFrequency);

        setTimeout(() => {
            setOffset(targetOffset);
        }, 10);

        return () => {
            clearInterval(intervalId);
        };
    }, [value]);

    return (
        <Wrapper {...props} position="relative">
            <svg viewBox="0 0 120 120">
                <circle
                    cx="60"
                    cy="60"
                    r={radius}
                    stroke={colors.getColor('background.00')}
                    strokeWidth="6"
                    fill="none"
                    strokeLinecap="round"
                    strokeDasharray={`${visibleCircumference} ${totalCircumference}`}
                    transform="rotate(135 60 60)"
                />
                <circle
                    cx="60"
                    cy="60"
                    r={radius}
                    stroke={colors.getColor(color)}
                    strokeWidth="7"
                    fill="none"
                    strokeLinecap="round"
                    strokeDasharray={`${visibleCircumference} ${totalCircumference}`}
                    strokeDashoffset={offset}
                    style={{ transition: `stroke-dashoffset ${duration}ms ease-out` }}
                    transform="rotate(135 60 60)"
                />
            </svg>
            <Wrapper style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', fontSize: '18px', textAlign: 'center', width: "100%" }}>

                {displayData === 'percentage' && <Text fontSize={['tiny', 'small'].includes(size) ? 'tiny' : fontSize}>{Math.floor(counter)}%</Text>}

                {
                    ['value', 'currency'].includes(displayData) && (
                        <Wrapper flexbox column center>
                            <Text fontSize={fontSize ?? "big"} strong>{displayData === 'value' ? currentValue : shortCurrency(currentValue)}</Text>
                            <Text fontSize={fontSize ?? "small"} opacity="0.5">{displayData === 'value' ? totalValue : shortCurrency(totalValue)}</Text>
                        </Wrapper>
                    )
                }
            </Wrapper>
        </Wrapper>
    );
}

ProgressGauge.propTypes = propTypes
ProgressGauge.defaultProps = defaultProps

export default withStyles(ProgressGauge);
