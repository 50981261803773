import { DEFAULT_IMAGES, TEAM_IMAGES, USER_IMAGES } from '../../../Enviroment';
import { Wrapper, Text } from '../../../components';
import PropTypes from 'prop-types';
import CheckedImage from '../CheckedImage';
import ProfileModal from '../ProfileModal';
import styled from 'styled-components';

const propTypes = {
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.oneOf([false])]),

    name: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),

    src: PropTypes.oneOfType([PropTypes.string, PropTypes.oneOf([false])]),

    profileType: PropTypes.oneOf(['user', 'team']),

    children: PropTypes.node,

    textProps: PropTypes.object,
    imageProps: PropTypes.object,
};

const PROFILE_INFO_SIZE = {
    mini: { width: '24px', height: '24px' },
    small: { width: '40px', height: '40px' },
    medium: { width: '60px', height: '60px' },
    large: { width: '80px', height: '80px' },
    big: { width: '100px', height: '100px' },
    huge: { width: '150px', height: '200px' },
};

const getSrcPrefix = (profileType) => {
    switch (profileType) {
        case 'team':
            return TEAM_IMAGES;
        default:
            return USER_IMAGES;
    }
};

const StyledCheckedImage = styled(CheckedImage)`
    border-radius: ${({ borderRadius }) => borderRadius || '100px'};
    object-fit: ${({ imageFit }) => imageFit || 'cover'};
    width: ${({ size, width, isTeam }) => (isTeam ? 'auto' : width ? width : PROFILE_INFO_SIZE[size]?.width || PROFILE_INFO_SIZE.medium.width)};
    height: ${({ size, height }) => (height ? height : PROFILE_INFO_SIZE[size]?.height || PROFILE_INFO_SIZE.medium.height)};
`;

const ProfileInfo = ({ children, id, name, size, src, profileType = 'user', textProps, imageProps, ...props }) => {
    const srcPrefix = getSrcPrefix(profileType);

    return (
        <Wrapper flexbox align='center' gap='normal' {...props}>
            {src && (
                <StyledCheckedImage
                    alt={profileType}
                    src={`${srcPrefix}/${src}`}
                    defaultSrc={`${DEFAULT_IMAGES}/${profileType}.png`}
                    size={size}
                    isTeam={profileType === 'team'}
                    {...imageProps}
                />
            )}

            {name && (
                <Wrapper flexbox column gap='small'>
                    {id ? (
                        <ProfileModal
                            userSrc={`${USER_IMAGES}/${src}`}
                            userId={id}
                            triggerComponent={
                                <Text
                                    fontSize={textProps?.fontSize ? textProps?.fontSize : PROFILE_INFO_SIZE[size]?.fontSize}
                                    underlineHover
                                    size={size}
                                    {...textProps}
                                >
                                    {name}
                                </Text>
                            }
                        />
                    ) : (
                        <Text {...textProps}>{name}</Text>
                    )}
                    {children}
                </Wrapper>
            )}
        </Wrapper>
    );
};

ProfileInfo.propTypes = propTypes;

export default ProfileInfo;
