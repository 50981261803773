import withStyles from '../../assets/services/styles'
import { colors } from '../../assets/theme'
import { keys } from 'ramda'

import {
  bg,
  border,
  attached,
  padding,
  gap,
  font,
  flexbox,
  margin,
  radius,
  corner,
} from '../../assets/theme/by'

const styles = `
  background: transparent;
  border: none;
  display: flex;
  flex: 1;
  height: 100%;
  outline: none;
  box-shadow: none;
  padding: 0 15px;
`

const sizes = {
  xsmall: `
      font-size: 1.0rem;  
      padding: 0.3em 0.5em
    `,
  small: `
      font-size: 0.90rem;
      padding: 0.6em 1.3em;
      border-radius: 9px;
    `,
  normal: `
        font-size: 0.92rem;
        padding: 0.3em 0.5em;
        font-weight: normal;
    `,
  large: `font-size: 1.12rem;`,
  huge: `
      font-size: 1.3rem;
      padding: 0.9em 1.3em;
    `,
}

const options = {
  size: sizes,

  formControl: `
        background-color: red;
    `,
  readOnly: `
        padding-left: 0;
        text-align: left;
        align-self: stretch;
    `,
  disabled: `
      cursor: not-allowed;
      background: ${colors.getColor("background.02")};
      color: ${colors.getColor("background.10")};

      ::placeholder {
        color: ${colors.getColor("background.10")};
      }
    `,
}

export const props = {
  sizes: keys(sizes),
}

export default withStyles(styles, options)