import React, { useState } from "react";
import Wrapper from "../Wrapper";
import Text from "../Text";
import styled from "styled-components";
import PropTypes from 'prop-types'

const SwitchContainer = styled(Wrapper)`
  position: relative;
`;

const SelectedIndicator = styled(Wrapper).attrs(props => ({
    style: {
        width: `calc(100% / ${props.totalOptions || 1})`
    }
  }))`
    position: absolute;
    height: 100%;
    transition: transform 0.3s ease-out;  
  `
  
const OptionWrapper = styled(Wrapper)`
  position: relative;
  z-index: 1;
`;

const propTypes = {

    options: PropTypes.arrayOf(PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string
    })),

    selected: PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string
    }),

    styles: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),

    onChange: PropTypes.oneOfType([PropTypes.bool, PropTypes.func])

}

const defaultProps = {
    styles: false,
    onChange: false,
}

const Switch = ({ options, selected, styles, onChange }) => {

    const [selectedOption, setSelectedOption] = useState(selected)

    function onChangeSwitch(option, index) {
        setSelectedOption({ ...option, index });
        onChange && onChange(option)
    }

    return (
        <Wrapper flexbox padding='5px' corner="default" bgColor={styles?.bgColor || 'background.02'}>
            <SwitchContainer flexbox>
                <SelectedIndicator totalOptions={options.length} bgColor={styles?.selectedColor || 'background.04'} style={{ transform: `translateX(${selectedOption.index * 100}%)` }} />
                {
                    options?.map((option, index) => {
                        return (
                            <OptionWrapper corner="default" flexbox center key={index} padding='0.3rem 1rem' onClick={() => onChangeSwitch(option, index)} fontSize={styles?.fontSize || ''}>
                                <Text color={styles?.textColor || 'white'}>{option.name}</Text>
                            </OptionWrapper>
                        )
                    })
                }
            </SwitchContainer>
        </Wrapper>
    )
}

Switch.propTypes = propTypes
Switch.defaultProps = defaultProps

export default Switch