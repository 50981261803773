import React, { useRef, useState, useEffect } from 'react';
import { Wrapper, Text, Icon } from '../../components';
import withStyles, { props } from './styles';
import { omit, keys } from 'ramda';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { NumericFormat } from 'react-number-format';

const InputWrapper = styled(Wrapper)`
    border: 0.5px solid rgba(255, 255, 255, 0.14);

    & input:focus,
    & textarea:focus {
        outline: none !important;
        border: none !important;
    }

    &:hover {
        border-bottom: ${(props) => (!props.isFocus && !props.alwaysActiveBorders ? '1.5px solid rgba(1, 169, 132, 0.35)' : '')};
    }
`;

const propTypes = {
    size: PropTypes.oneOf(props.sizes),

    icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),

    width: PropTypes.string,

    textarea: PropTypes.bool,

    isRequired: PropTypes.bool,

    alwaysActiveBorders: PropTypes.bool,
};

const defaultProps = {
    size: 'normal',
    icon: false,
    width: '100%',
    textarea: false,
    isRequired: false,
    alwaysActiveBorders: false,
};

const htmlProps = (props) => omit(keys(defaultProps), props);

const Input = ({ id, icon, value, placeholder, width, label, height, isRequired, onChange, ...props }) => {
    const inputRef = useRef();

    const [inputValue, setInputValue] = useState(value || '');
    const [isFocus, setIsFoucus] = useState(false);
    const [showCloseIcon, setShowCloseIcon] = useState(false);

    const handleInput = () => {
        const currentValue = inputRef.current.value;

        if (currentValue.length > 0) {
            setShowCloseIcon(true);
        } else {
            setShowCloseIcon(false);
        }
    };

    const clearInput = () => {
        inputRef.current.value = '';
        handleInput();

        if (onChange) {
            const fakeEvent = {
                target: { value: inputRef.current.value, id, name: props.name },
            };
            onChange(fakeEvent);
        }
        setInput();
    };

    const handleCurrencyInput = (event) => {
        const currencyString = event.target.value;

        let numberString = currencyString.replace('R$', '');

        numberString = numberString.replace(/\./g, '').replace(',', '.').replace(/\s+/g, '');

        const fakeEvent = {
            target: { value: parseFloat(numberString), id },
        };

        return fakeEvent;
    };

    const onChangeInput = (event) => {
        setInputValue(event.target.value);

        onChange && onChange(event);
    };

    const setInput = () => {
        setIsFoucus(true);
        handleInput();
        inputRef.current.focus();
    };

    useEffect(() => {
        setInputValue(value !== undefined && value !== null ? value : '');
    }, [value]);

    return (
        <Wrapper flexbox column gap='0.5rem' width={width}>
            {label && (
                <Wrapper opacity='0.65'>
                    <Text fontSize='tiny'>
                        {label}
                        {isRequired && '*'}
                    </Text>
                </Wrapper>
            )}
            <InputWrapper
                flexbox
                height={height}
                align='center'
                bgColor={props?.bgColor || 'background.01'}
                isFocus={isFocus}
                alwaysActiveBorders={props.alwaysActiveBorders}
                corner='default'
                padding={icon && !props?.disabled ? '0 5px' : ''}
            >
                {icon && (
                    <Icon
                        onClick={() => {
                            setInput();
                        }}
                        name='search'
                        opacity='0.5'
                        fill='white'
                        size={20}
                    />
                )}
                {props.type === 'textarea' ? (
                    <textarea
                        id={id}
                        ref={inputRef}
                        style={{ width: '100%', height: height, resize: 'none', boxShadow: 'none', fontFamily: 'inter' }}
                        onFocus={() => {
                            setIsFoucus(true);
                            handleInput();
                        }}
                        onBlur={() => setIsFoucus(false)}
                        onInput={handleInput}
                        placeholder={placeholder}
                        onChange={(event) => onChangeInput(event)}
                        value={inputValue}
                        {...htmlProps(props)}
                    />
                ) : props.type === 'currency' ? (
                    <NumericFormat
                        id={id}
                        getInputRef={inputRef}
                        style={{ width: '100%', height: height }}
                        onFocus={() => {
                            setIsFoucus(true);
                            handleInput();
                        }}
                        onBlur={() => setIsFoucus(false)}
                        onInput={handleInput}
                        placeholder={placeholder}
                        thousandSeparator='.'
                        decimalSeparator=','
                        prefix='R$ '
                        decimalScale={2}
                        onChange={(event) => {
                            onChange && onChange(handleCurrencyInput(event));
                        }}
                        value={inputValue}
                        {...htmlProps(props)}
                    />
                ) : (
                    <input
                        id={id}
                        ref={inputRef}
                        style={{ width: '100%', height: height, boxShadow: 'none' }}
                        onFocus={() => {
                            setIsFoucus(true);
                            handleInput();
                        }}
                        onBlur={() => setIsFoucus(false)}
                        onInput={handleInput}
                        placeholder={placeholder}
                        onChange={(event) => onChangeInput(event)}
                        value={inputValue}
                        {...htmlProps(props)}
                    />
                )}

                {showCloseIcon && !props?.disabled && props.type !== 'currency' && (
                    <Icon name='close' opacity='0.5' fill='white' size={20} onClick={clearInput} cursor='pointer' />
                )}
            </InputWrapper>
        </Wrapper>
    );
};

Input.propTypes = propTypes;
Input.defaultProps = defaultProps;

export default withStyles(Input);
