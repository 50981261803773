import { useEffect, useRef, useCallback } from 'react';
import io from 'socket.io-client';

export const useSocket = (BASE_URL, details, onUpdate) => {
    const socketRef = useRef(null);

    useEffect(() => {
        if (!socketRef.current) {
            socketRef.current = io(BASE_URL, {
                path: '/socket.io',
                transports: ['websocket'],
                reconnectionAttempts: Infinity,
                perMessageDeflate: false,
            });
        }

        const socket = socketRef.current;

        socket.on('connect', () => {
            console.log('ENTREI: ', details.chatId);

            socket.emit('join-chat', details, (response) => {
                console.log('né: ', details.chatId);
                if (response) {
                    console.log('Ingressou na sala com sucesso:', details.chatId);
                } else {
                    console.error('Falha ao ingressar na sala:', details.chatId);
                }
            });
        });

        socket.on('disconnect', (reason) => {
            console.warn(`Conexão desconectada: ${reason}`);
        });

        socket.on('connect_error', (err) => {
            console.error('Erro ao conectar ao servidor Socket.IO:', err.message);
        });

        socket.on('reconnect', () => {
            console.log('Reconectado ao servidor Socket.IO:', socket.id);

            socket.emit('join-chat', details, (response) => {
                if (response) {
                    console.log('Ingressou na sala com sucesso:', details.chatId);
                } else {
                    console.error('Falha ao ingressar na sala:', details.chatId);
                }
            });
        });

        socket.on('reconnect_attempt', (attemptNumber) => {
            console.info(`Tentativa de reconexão #${attemptNumber}`);
        });

        console.log('AAAAAAAAAAAAAAAAAAAAAAAAAAAAA1');
        socket.on('chat-update', (res) => {
            console.log('resresresresresres res:', res);
            if (onUpdate) onUpdate(res);
        });

        return () => {
            if (socket) {
                socket.emit('leave-chat', details);
                socket.disconnect();
                socketRef.current = null;
            }
        };
    }, [BASE_URL]);

    const sendEmit = useCallback((event, data) => {
        if (socketRef.current) {
            console.log('--------- ---:', event, data);
            socketRef.current.emit(event, data);
        } else {
            console.error('Socket não está conectado');
        }
    }, []);

    return { socket: socketRef.current, sendEmit };
};
