import React, { useEffect } from 'react';
import { Wrapper, Text } from '../../../../../components';
import { FormField } from '../../Components';
import { isAdmin, isCoreSector, removeElementsOpacity } from '../../../../../assets/services/apis';
import Loading from '../../../Loading';

const Form = ({ requestUserInfo, viewInfo, isLoading, onEditFormField }) => {

    const requestUserSector = requestUserInfo.sectorId
    const isAbleToUpdateForm = isCoreSector(requestUserSector, true, true) || isAdmin(requestUserSector)

    const formScopes = [
        { key: 'personal', name: 'Informações Pessoais' },
        { key: 'professional', name: 'Informações Profissionais' },
        { key: 'finance', name: 'Informações Financeiras' },
        { key: 'objectives', name: 'Objetivos e Metas' },
    ]

    useEffect(() => {
        viewInfo && removeElementsOpacity(100, 'opacity_card_form')
    }, [viewInfo])

    return (
        <Wrapper flexbox column padding='2rem' gap='3rem'>

            {
                formScopes.map((scope, index) => {
                    return (
                        <Wrapper key={index} flexbox column gap>

                            <Text medium>{scope.name}</Text>

                            {
                                !isLoading ? (
                                    <Wrapper flexbox column fontSize='small'>

                                        {
                                            Array.from({ length: Math.ceil(viewInfo?.[scope.key]?.length / 2) }).map((_, idx) => {

                                                const firstItem = viewInfo?.[scope.key]?.[2 * idx];
                                                const secondItem = viewInfo?.[scope.key]?.[2 * idx + 1];

                                                return (
                                                    <Wrapper key={idx} flexbox justify='space-between' height='fit-content'>

                                                        <Wrapper flexbox width='49%'>

                                                            <FormField
                                                                fieldKey={firstItem.key}
                                                                name={firstItem.name}
                                                                value={firstItem.value}
                                                                type={firstItem.type}
                                                                isEditable={isAbleToUpdateForm && firstItem.isEditable}
                                                                onEdit={(key, value) => onEditFormField(key, value)}
                                                            />

                                                        </Wrapper>

                                                        {
                                                            secondItem && (
                                                                <Wrapper flexbox width='49%'>

                                                                    <FormField
                                                                        fieldKey={secondItem.key}
                                                                        name={secondItem.name}
                                                                        value={secondItem.value}
                                                                        type={secondItem.type}
                                                                        isEditable={isAbleToUpdateForm && secondItem.isEditable}
                                                                        onEdit={(key, value) => onEditFormField(key, value)}
                                                                    />

                                                                </Wrapper>
                                                            )
                                                        }

                                                    </Wrapper>
                                                )
                                            })
                                        }

                                    </Wrapper>
                                ) : (
                                    <Wrapper flexbox column gap>
                                        {
                                            [1, 2, 3].map(idx => {
                                                return <Loading key={idx} type='box' width='50%' height='2rem' />
                                            })
                                        }
                                    </Wrapper>
                                )
                            }


                        </Wrapper>
                    )
                })
            }

        </Wrapper>
    )
}

export default Form