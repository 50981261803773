import React from 'react';
import Wrapper from '../../Wrapper';
import Icon from '../../Icon';
import Text from '../../Text';
import styled from 'styled-components';
import FilterWindow from './FilterWindow';
import { Tooltip } from '../..';
import { colors } from '../../../assets/theme';

const ActiveWrapper = styled(Wrapper)`
    opacity: ${props => props.sortable ? "0.8" : "1"};
    cursor: ${props => props.sortable ? "pointer" : "default"};
    transition: opacity 0.3s ease;

    &:hover {
        opacity: 1;
    }
`

const TableHeader = ({ sortedData, columns, matchesSearch, setCurrentPaginationPage, state, headerProps, dispatch, searchInputValue, isSelectable, handleToggleAllRows, dropdownColumn, ...props }) => {

    const handleSort = (key) => {
        let direction = 'descending';
        if (state.sortConfig && state.sortConfig.key === key) {
            if (state.sortConfig.direction === 'descending') {
                direction = 'ascending';
            } else if (state.sortConfig.direction === 'ascending') {
                direction = null;
                key = null;
            }
        }
        dispatch({ type: 'SET_SORT_CONFIG', sortConfig: { key, direction } });
    };

    const getSortIcon = (key) => {
        if (!state.sortConfig || state.sortConfig.key !== key) {
            return 'unfold_more';
        }
        return state.sortConfig.direction === 'ascending' ? 'expand_less' : 'expand_more';
    };

    return (
        <tr style={{ backgroundColor: headerProps?.background && headerProps?.background, lineHeight: headerProps?.lineHeight ? headerProps?.lineHeight : '50px' }}>
            {
                columns.map((column, index) => (
                    column?.header &&
                    <th key={`${column.key}-${index}`} colSpan={column?.colSpan || 1} style={{ paddingLeft: column?.centerHeader ? '0' : headerProps?.initialPadding || '0.75rem', paddingRight: (dropdownColumn && (index + 2 === columns?.length)) ? '42px' : ''}}>

                        <Wrapper position='relative' flexbox align='center' justify={column?.centerHeader ? 'center' : '' }>

                            {isSelectable && index === 0 && (
                                <Wrapper width="50px" align='center' flexbox thin fontSize="tiny" margin="0 1.1rem 0 0">
                                    <Wrapper onClick={handleToggleAllRows} cursor="pointer" margin="0 5px 0 0" flexbox center height='19.19px' width='19.19px' minWidth='1.2rem' corner='default' border bgColor={state?.selectedRows?.length > 0 ? 'border.base' : 'background.01'}
                                    >
                                        <Icon name='remove' fill='background.01' size={20} />
                                    </Wrapper>
                                    <Text color='#929292'>({state?.selectedRows?.length})</Text>
                                </Wrapper>
                            )}

                            <Wrapper flexbox align='center' gap='small' style={{whiteSpace: "nowrap"}}>

                                <ActiveWrapper sortable={column.sortable} flexbox gap='small' align='center' onClick={() => column.sortable && handleSort(column.key)} opacity='0.5'>

                                    <Text color={headerProps?.color} weight={headerProps?.fontWeight} medium={!headerProps?.fontWeight ?? "medium"} fontSize={headerProps?.fontSize || false}>
                                        {column.header}
                                    </Text>

                                    {column?.headerTooltip && (
                                        <Tooltip isArrowType overlayStyle={{ width: "200px" }} key={`goal_${index}`} description={column?.headerTooltip}>
                                            <Wrapper flexbox>
                                                <Icon name='help' size={16} fill={`${colors.getColor('white')}4D`} />
                                            </Wrapper>
                                        </Tooltip>
                                    )}
                                    {
                                        column.sortable && (
                                            <Icon name={getSortIcon(column.key)} size={20} fill='white' />
                                        )
                                    }



                                </ActiveWrapper>

                                {
                                    column.filter && (
                                        <FilterWindow
                                            column={column}
                                            sortedData={sortedData}
                                            matchesSearch={matchesSearch}
                                            state={state}
                                            dispatch={dispatch}
                                            setCurrentPaginationPage={setCurrentPaginationPage}
                                            searchInputValue={searchInputValue}
                                        />
                                    )
                                }
                            </Wrapper>

                        </Wrapper>

                    </th>
                ))
            }
        </tr>
    )
}

export default TableHeader