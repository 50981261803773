import React, { useEffect, useState } from 'react';
import { Icon, Text, Tooltip, Wrapper } from '../../../../../../../components';
import { colors } from '../../../../../../../assets/theme';
import { removeElementsOpacity } from '../../../../../../../assets/services/apis';

const extendedInfosStyle = {
    isSegmented: { color: '#AF60FF', icon: 'sync_alt', tooltip: 'Cliente Segmentado' },
    isTransfer: { color: '#48C8FF', icon: 'sync_problem', tooltip: 'Cliente com Desconto de Transf.' },
    isOpenNps: { color: '#1DC077', icon: 'add_reaction', tooltip: 'NPS em aberto' },
    isWithoutXpCode: { color: '#FF8D07', icon: 'person_alert', tooltip: 'Cliente sem Cód. XP' },
};

const ContactTile = ({ index, dealInfo, selectedInteraction, isRm, ...props }) => {
    const tileColor = `background.${index % 2 === 0 ? '05' : '07'}`;
    const buttonsCount = dealInfo?.extendedInfosArray?.filter((info) => info.value)?.length;

    const [isHoverCard, setIsHoverCard] = useState(false);

    const dealStatus = dealInfo?.[`${selectedInteraction}Status`];
    const dealStatusColor = dealStatus === 'ok' ? '' : dealStatus;

    const UpperSelectedInteraction = selectedInteraction === 'contact' ? 'Contact' : 'Meeting';

    const splittedCustomerName = dealInfo?.dealName?.split(' ');

    const getSeparatedCustomerName = () => {
        if (splittedCustomerName.length === 2) {
            return { topName: splittedCustomerName[0], bottomName: splittedCustomerName[1] };
        } else if (splittedCustomerName.length > 2) {
            const topName = splittedCustomerName.slice(0, 1).join(' ');
            const bottomName = splittedCustomerName.slice(1).join(' ');

            return { topName, bottomName };
        }

        return { topName: splittedCustomerName[0], bottomName: '' };
    };

    const handleIconClick = (event, link) => {
        event.stopPropagation();
        window.open(link).focus();
    };

    useEffect(() => {
        removeElementsOpacity(0);
    }, [isHoverCard]);

    return (
        <Wrapper
            {...props}
            flexbox
            bgColor={tileColor}
            bgHover='background.02'
            corner='default'
            padding
            gap
            fontSize='tiny'
            align='center'
            height={!isHoverCard && '4rem'}
            maxHeight={!isHoverCard ? '4rem' : '7rem'}
            transition='background-color 0.15s ease-in-out, max-height 0.15s ease-in-out'
            onMouseEnter={() => setIsHoverCard(true)}
            onMouseLeave={() => {
                setIsHoverCard(false);
            }}
            style={{ borderLeft: `5px solid ${colors.getColor(dealStatus === 'ok' ? 'background.09' : dealStatus)}` }}
        >
            <Wrapper flexbox column width='30%' transition='max-height 0.3s ease-in-out' style={{ overflowX: 'hidden', alignSelf: 'start' }}>
                <Text truncate={!isHoverCard}>{getSeparatedCustomerName().topName}</Text>
                <Text truncate={!isHoverCard} opacity='0.7'>
                    {getSeparatedCustomerName().bottomName}
                </Text>
            </Wrapper>

            <Wrapper flexbox column width={!isHoverCard ? '35%' : '20%'} transition='width 0.2s ease-in-out'>
                {dealInfo.stageId !== 36 ? (
                    <Text color={dealStatusColor}>
                        {isRm ? dealInfo?.[`daysSinceLast${UpperSelectedInteraction}`] : dealInfo?.[`daysToNext${UpperSelectedInteraction}`]} dias
                    </Text>
                ) : (
                    <Text color='text.dark'>-</Text>
                )}
                <Text opacity='0.7' truncate>
                    {dealInfo?.[`last${UpperSelectedInteraction}Activity`]}
                </Text>
            </Wrapper>

            <Wrapper flexbox width={!isHoverCard ? '35%' : '50%'} justify='end' gap transition='width 0.2s ease-in-out'>
                <Wrapper
                    flexbox
                    gap='0.5rem'
                    width={`${isHoverCard ? (buttonsCount + 2) * 2 : buttonsCount * 2}rem`}
                    transition='width 0.2s ease-in-out'
                >
                    {dealInfo?.extendedInfosArray
                        .filter((info) => info.value)
                        .map((info, idx) => {
                            const infoStyle = extendedInfosStyle[info.key];
                            return (
                                <Tooltip type='simple' key={idx} description={infoStyle.tooltip}>
                                    <Wrapper flexbox center padding='4px' bgColor={`${infoStyle.color}1F`} corner='default'>
                                        <Icon name={infoStyle.icon} fill={infoStyle.color} size={18} />
                                    </Wrapper>
                                </Tooltip>
                            );
                        })}

                    {isHoverCard && (
                        <Wrapper className='opacity_card' flexbox gap='0.5rem'>
                            <Tooltip type='simple' description='Pipe drive'>
                                <Wrapper
                                    flexbox
                                    center
                                    padding='4px'
                                    bgColor='background.05'
                                    corner='default'
                                    onClick={(event) => handleIconClick(event, `https://guiainvest-270444.pipedrive.com/deal/${dealInfo.dealId}/`)}
                                >
                                    <Icon name='pipedrive' fill='white' size={16} />
                                </Wrapper>
                            </Tooltip>

                            <Tooltip type='simple' description='Hub XP'>
                                <Wrapper
                                    flexbox
                                    center
                                    padding='4px'
                                    bgColor='background.05'
                                    corner='default'
                                    onClick={(event) =>
                                        handleIconClick(
                                            event,
                                            `https://hub.xpi.com.br/rede/#/customers/${window.btoa(dealInfo.customerCode)}/consolidated-position`
                                        )
                                    }
                                >
                                    <Icon name='xp' fill='white' size={20} />
                                </Wrapper>
                            </Tooltip>
                        </Wrapper>
                    )}
                </Wrapper>
            </Wrapper>
        </Wrapper>
    );
};

export default ContactTile;
