import React, { useState } from 'react';
import Wrapper from '../../Wrapper';
import Button from '../../Button';
import Input from '../../Input';
import Text from '../../Text';
import Icon from '../../Icon';
import { downloadExcel } from '../../../assets/services/apis';
import styled, { keyframes } from 'styled-components';

const rotate = keyframes`
    to {
      transform: rotate(360deg);
    }
`;

const LoaderWrapper = styled.div`
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50%;

    &:before,
    &:after {
        content: "";
        position: absolute;
        border-radius: inherit;
    }

    &:before {
        width: 100%;
        height: 100%;
        background-image: linear-gradient(0deg, #1DC077 0%, #1E1E1E 100%);
        animation: ${rotate} 0.5s infinite linear;
    }

    &:after {
        width: 85%;
        height: 85%;
        background-color: #222;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
`;

const ActionsSection = ({ hasSearch, hasExport, columns, data, setCurrentPaginationPage, setDebounceInputValue, formatController }) => {

    const [inputValue, setInputValue] = useState('')
    const [isLoadingDebounceInput, setIsLoadingDebounceInput] = useState(false)

    React.useEffect(() => {
        if (!isLoadingDebounceInput) setIsLoadingDebounceInput(true)

        const timeoutId = setTimeout(() => {
            setDebounceInputValue(inputValue);
            setCurrentPaginationPage(0);
            setIsLoadingDebounceInput(false);
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [inputValue]);

    // function getFilterValues(filterKey) {
    //     return state?.filterOptions?.filters[filterKey]
    // }

    // function getFilterKeys() {
    //     if (state?.filterOptions?.filters) {
    //         return Object.keys(state?.filterOptions?.filters).filter(key => state?.filterOptions?.filters[key].length > 0)
    //     }
    //     return []
    // }

    function exportExcel() {
        const headers = columns.filter(column => column?.type !== 'dropdown').map(column => {
            return {
                key: column.key,
                name: column.header,
                format: Object.keys(formatController).includes(column?.excelFormat) ?
                    formatController[column.excelFormat] : column?.excelFormat || false
            }
        })

        const title = { workbook: 'Relatório', file: 'Relatório' }

        downloadExcel(data, headers, title)
    }

    return (
        <Wrapper flexbox column bgColor='background.02'>

            <Wrapper flexbox width='100%' padding={(hasSearch || hasExport) && '0.5rem 0.75rem'}>

                {
                    hasSearch && (
                        <Wrapper flexbox gap width='100%' align='center'>

                            <Input
                                icon='search'
                                placeholder='Pesquisar...'
                                width='20%'
                                height='2rem'
                                value={inputValue}
                                onChange={(event) => { setInputValue(event.target.value) }}
                            />

                            {
                                isLoadingDebounceInput && (
                                    <LoaderWrapper />
                                )
                            }

                        </Wrapper>
                    )
                }

                {
                    hasExport && (
                        <Wrapper flexbox width='50%' justify='end' height='fit-content'>
                            <Button color='#147A4C' onClick={() => exportExcel()} size='small'>
                                <Text>Exportar Excel</Text>
                                <Icon name='download' fill='white' size={20} />
                            </Button>
                        </Wrapper>
                    )
                }

            </Wrapper>

            {/* {
                        filterTags && (
                            <Wrapper flexbox align='center' gap padding='0.5rem 0.75rem'>
                                <Text fontSize='tiny' opacity='0.7'>Filtros:</Text>

                                <Wrapper flexbox align='center' gap>
                                    {
                                        getFilterKeys().length > 0 && (
                                            getFilterKeys().map((filter) => {
                                                return (
                                                    getFilterValues(filter).map((value, filtIdx) => {
                                                        return (
                                                            <Wrapper key={filtIdx} flexbox bgColor='#295B45' corner='rounded' padding='0.5rem' gap='0.5rem' fontSize='tiny' align='center'>
                                                                <Text regular opacity='0.8'>{columns.find(column => column.key === filter)?.header}:</Text>
                                                                <Text strong opacity='0.8'>{value}</Text>
                                                                <Icon name='close' fill='white' size={15} onClick={() => { handleFilterOptions(value, filter) }} style={{ cursor: 'pointer' }} />
                                                            </Wrapper>
                                                        )
                                                    })
                                                )
                                            })
                                        )
                                    }
                                </Wrapper>
                            </Wrapper>
                        )
                    } */}
        </Wrapper>
    )
}

export default ActionsSection